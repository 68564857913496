import {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {addresses} from 'src/constants';
import {IJsonRPCError} from 'src/slices/interfaces';
import {error, success} from 'src/slices/MessagesSlice';
import {clearPendingTxn, fetchPendingTxns} from 'src/slices/PendingTxnsSlice';
import {useWeb3Context} from '.'
import {ethers} from "ethers";
import {abi as NFTGameAbi} from "../abis/NFTGame1155.json";
import {abi as FootBallNFT} from "../abis/FootballNFT.json";
import {loadAppDetails} from 'src/slices/AppSlice';
import {abi as NFTabi} from "../abis/FootballNFT.json";
import {increment} from "../slices/CounterSlice";


interface RoundInfo {
    started: string,
    startTimestamp: string,
    endTimestamp: string,
    stakedNFT: string,
    stakedTier1: string,
    stakedTier2: string,
    stakedTier3: string,
    stakedTier4: string,
    prize: string,
    claimablePrize: string,
    prizeLastUpdateTime: string,
    prizeWeight: string,
    rewardPerSecond: string,
}

export default function useStake() {

    const {chainID, address, provider} = useWeb3Context()
    const dispatch = useDispatch()

    const [roundInfoListState, setRoundInfoListState] = useState({})

    const [isApprovalForAll, setIsApprovalForAll] = useState(false)


    const counter = useSelector((state: any) => {
        // console.log("useStake useSelector state.counter", state.counter)
        return state.counter
    })

    useEffect(() => {
        (async () => {
            try {
                if (provider && address) {
                    // console.log("useClaim::", chainID, address, provider)
                    const nftGameContract = new ethers.Contract(addresses[chainID].FootballNFT, FootBallNFT, provider);
                    let isApproved = await nftGameContract.isApprovedForAll(address, addresses[chainID].NFTGame);
                    setIsApprovalForAll(isApproved);
                }
            } catch (e) {
                console.log(e)
                const rpcError = e as IJsonRPCError;
                rpcError.data ? dispatch(error(rpcError.data.message)) : dispatch(error(rpcError.message))
            }
        })()
        return () => {
        };
    }, [chainID, address, provider, counter]);


    useEffect(() => {
        (async () => {
            if (provider) {
                try {
                    // console.log("useClaim::", chainID, address, provider)
                    const nftGameContract = new ethers.Contract(addresses[chainID].NFTGame, NFTGameAbi, provider);

                    const getRoundInfo = await nftGameContract.getRoundInfo();

                    let roundInfoList = [];
                    for (let i = 0; i < 7; i++) {
                        let roundInfo = {} as RoundInfo;
                        roundInfo.started = Number(getRoundInfo[i * 13 + 0]) + '';
                        roundInfo.startTimestamp = Number(getRoundInfo[i * 13 + 1]) + '';
                        roundInfo.endTimestamp = Number(getRoundInfo[i * 13 + 2]) + '';
                        roundInfo.stakedNFT = Number(getRoundInfo[i * 13 + 3]) + '';
                        roundInfo.stakedTier1 = Number(getRoundInfo[i * 13 + 4]) + '';
                        roundInfo.stakedTier2 = Number(getRoundInfo[i * 13 + 5]) + '';
                        roundInfo.stakedTier3 = Number(getRoundInfo[i * 13 + 6]) + '';
                        roundInfo.stakedTier4 = Number(getRoundInfo[i * 13 + 7]) + '';
                        roundInfo.prize = Number(getRoundInfo[i * 13 + 8]) + '';
                        roundInfo.claimablePrize = Number(getRoundInfo[i * 13 + 9]) + '';
                        roundInfo.prizeLastUpdateTime = Number(getRoundInfo[i * 13 + 10]) + '';
                        roundInfo.prizeWeight = Number(getRoundInfo[i * 13 + 11]) + '';
                        roundInfo.rewardPerSecond = Number(getRoundInfo[i * 13 + 12]) + '';
                        roundInfoList.push(roundInfo);
                    }
                    setRoundInfoListState(roundInfoList);
                } catch (e) {
                    console.log(e)
                    const rpcError = e as IJsonRPCError;
                    // rpcError.data ? dispatch(error(rpcError.data.message)) : dispatch(error(rpcError.message))
                }


            }

        })()
        return () => {
        };
    }, [chainID, address, provider, counter]);

    const stakeNFT = useCallback(
        async (selected, selectedRound) => {
            // console.log("preparing to stakeNFT::", selected, "selectedRound::", selectedRound)
            let roundId = selectedRound + 1;

            if (selected.length > 0 && chainID && provider && address) {
                let tx: any;
                try {

                    const nftGameContract = new ethers.Contract(addresses[chainID].NFTGame, NFTGameAbi, provider.getSigner());
                    let owner = await nftGameContract.owner();
                    // console.log("nftGameContract owner::", owner)

                    let teamMap = new Map();
                    let stake_param_teamIds = [] as any;
                    let stake_param_teamCounts = [] as any;
                    for (let i = 0; i < selected.length; i++) {
                        let teamKey = selected[i];
                        let teamId = teamKey.toString().split(":")[0]
                        let teamCount = teamKey.toString().split(":")[1]

                        if (teamMap.has(teamId)) {
                            teamMap.set(teamId, teamMap.get(teamId) + 1);
                        } else {
                            teamMap.set(teamId, 1);
                        }

                    }
                    teamMap.forEach((value, key) => {
                        stake_param_teamIds.push(key);
                        stake_param_teamCounts.push(value);
                    })

                    // console.log("stake_param_teamIds::", stake_param_teamIds)
                    // console.log("stake_param_teamCounts::", stake_param_teamCounts)

                    if (stake_param_teamIds.length == stake_param_teamCounts.length) {
                        // console.log("call stakeNFT");

                        tx = await nftGameContract.stake(roundId, stake_param_teamIds, stake_param_teamCounts);
                        dispatch(
                            fetchPendingTxns({txnHash: tx.hash, text: "Mint_NFT", type: "mint"}),
                        );
                        await tx.wait();
                        return tx

                    }

                } catch (e) {
                    console.log(e)
                    const rpcError = e as IJsonRPCError;
                    rpcError.data ? dispatch(error(rpcError.data.message)) : dispatch(error(rpcError.message))
                } finally {
                    if (tx) {
                        dispatch(loadAppDetails({networkID: chainID, provider}));
                        dispatch(clearPendingTxn(tx.hash));
                        dispatch(increment());
                        dispatch(success("stakeNFT Success!"));
                    }
                }
            }


        },
        [chainID, address, provider],
    );

    const approvedNFTAll = useCallback(
        async () => {

            if (chainID && provider && address) {
                let tx: any;
                try {

                    const FootBallNFTContract = new ethers.Contract(addresses[chainID].FootballNFT, FootBallNFT, provider.getSigner());

                    tx = await FootBallNFTContract.setApprovalForAll(addresses[chainID].NFTGame, true);
                    dispatch(
                        fetchPendingTxns({txnHash: tx.hash, text: "setApprovalForAll", type: "approve"}),
                    );
                    await tx.wait();
                    return tx


                } catch (e) {
                    console.log(e)
                    const rpcError = e as IJsonRPCError;
                    rpcError.data ? dispatch(error(rpcError.data.message)) : dispatch(error(rpcError.message))
                } finally {
                    if (tx) {
                        dispatch(loadAppDetails({networkID: chainID, provider}));
                        dispatch(clearPendingTxn(tx.hash));
                        dispatch(increment());
                        dispatch(success("approved Success!"));
                    }
                }


            }


        }, [chainID, address, provider],
    );

    return {isApprovalForAll, roundInfoListState, stakeNFT, approvedNFTAll}
}


