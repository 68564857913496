import {Menu} from "@headlessui/react";
import React from "react";
import {Box, Button, Dialog, Tooltip, useMediaQuery} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import {useState} from "react";
import {BigNumber, ethers} from "ethers";

import Close from "../../assets/images/close.svg";
import Select from "../../assets/images/select.svg";
import SelectedM from "../../assets/images/selected-m.svg";
import Selected from "../../assets/images/selected2.svg";
import useWalletInfo from "../../hooks/useWalletInfo";
import Live from "../../assets/images/live.svg";


import useStake from "../../hooks/useStake";
import {getTeamImage, teamIdToName, teamIdToTier} from "../../utils/TeamInfoUtils";
import {useSelector} from "react-redux";

export default function walletDialog(props) {
  const {show, setShowWallet, selectedRound, isApprovalForAll, fromPage, roundInfoListState} = props;

  // console.log("walletDialog selectedRound::", selectedRound);

  const [selectedFilterTier, setselectedFilterTier] = useState(0);


  const {approvedNFTAll, stakeNFT} = useStake();

  const {userAllIdCount, userTotalItem} = useWalletInfo();

  const isVerySmallScreen = useMediaQuery("(max-width: 680px)");
  const TierList = ["all", "Tier1", "Tier2", "Tier3", "Tier4"];
  const roundTitles = ["GROUP STAGE", "ROUND OF 16", "QUARTER-FINALS", "SEMI-FINALS", "3RD PLACE", "2ND PLACE", "1ST PLACE"];
  const [currTier, setCurrTier] = useState(TierList[0]);
  const [selectedTierTotalCount, setSelectedTierTotalCount] = useState(9999999);

  function walletInfo() {

    // console.log("walletDialog walletInfo selectedRound::", selectedRound);
    if (selectedRound < 8 && roundInfoListState && roundInfoListState.length > 0) {
      // console.log("walletDialog walletInfo roundInfoListState::", roundInfoListState);
      return {
        "walletInfoTitle": roundTitles[selectedRound],
        "prizeOfThisRound": roundInfoListState[selectedRound].prize,
        "totalStaked": roundInfoListState[selectedRound].stakedNFT,
        "stakedTier1": roundInfoListState[selectedRound].stakedTier1,
        "stakedTier2": roundInfoListState[selectedRound].stakedTier2,
        "stakedTier3": roundInfoListState[selectedRound].stakedTier3,
        "stakedTier4": roundInfoListState[selectedRound].stakedTier4,
        "endTimestamp": roundInfoListState[selectedRound].endTimestamp,
        "startTimestamp": roundInfoListState[selectedRound].startTimestamp,
      };
    } else {
      return {
        "walletInfoTitle": roundTitles[selectedRound],
        "prizeOfThisRound": 0,
        "totalStaked": 0,
        "stakedTier1": 0,
        "stakedTier2": 0,
        "stakedTier3": 0,
        "stakedTier4": 0,
      };
    }

  }

  const changeTier = i => {
    setCurrTier(TierList[i]);
    setselectedFilterTier(i);

    // let totalItem = 0;

    if (i === 0) {
      setSelectedTierTotalCount(999999999);
    } else {
      let totalItem = 0;
      for (let j = 0; j < userAllIdCount.length; j++) {
        let loopCount = Number(userAllIdCount[j]);
        if (i > 0 && i === teamIdToTier(j)) {
          totalItem += loopCount;
        }
      }
      setSelectedTierTotalCount(totalItem);
    }


  };

  const HtmlTooltip = withStyles(theme => ({
    placement: "left",
    arrow: {
      color: "#08071C",
    },
    tooltip: {
      backgroundColor: "#08071C",
      border: "1px solid #292865",
      color: "rgb(255,255,255,0.6)",
      minWidth: "300px",
      padding: "20px",
      borderRadius: "10px",
      fontSize: "13px",
      lineHeight: "20px",
      placement: "top",
    },
  }))(Tooltip);

  const handleClose = () => {
    setShowWallet(false);
  };

  const handleStake = (selected, selectedRound) => {
    // console.log("onClick handleStake");
    if (!isApprovalForAll) {
      approvedNFTAll();
    } else {
      stakeNFT(selected, selectedRound);
    }
  }

  const [selected, setSelected] = useState([]);

  function handleSelectAll() {

    if (selected.length === 0) {

      let allSelected = [];
      for (let i = 0; i < userAllIdCount.length; i++) {
        let loopCount = Number(userAllIdCount[i]);

        for (let j = 0; j < loopCount; j++) {
          allSelected.push(i + ":" + j);
        }
      }

      setSelected([...allSelected]);

    } else {
      setSelected([]);
    }
  }

  function ItemComponent(arr, index) {
    return isVerySmallScreen ?
      arr.map(item =>
        <div
          className="stakeItemBg"
          onClick={() => {
            // console.log("onClick ItemComponent item::", item);
            if (selected.includes(item)) {
              setSelected(selected.filter(i => i !== item));
            } else {
              setSelected([...selected, item]);
            }
            // console.log("click stakeItem", item);
          }}
        >
          {selected.includes(item) && (
            <div className="stakeItemAcitve">
              <img src={SelectedM}/>
            </div>
          )}
          <Box display="flex" className="stakeItem">
            <Box display="flex" justifyContent="center" alignItems={"center"} className="stakeItem-l">
              {/* <img src={getTeamImage(index)} height={80} /> */}
              <video x5-video-player-type="h5" playsInline muted id="video" preload="none" autoPlay loop
                     className="header-bg-video" poster="" height="100">
                <source src={getTeamImage(index)} type="video/mp4"/>
              </video>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent={"center"} className="stakeItem-r flex-1">
              <div className="font-14 font-weight-b">{teamIdToName(index)}</div>
              <Box display="flex" justifyContent="space-between" alignItems={"center"} className="">
                <div className="color3">Tier</div>
                <div className="color8 font-weight-b">{teamIdToTier(index)}</div>
              </Box>
            </Box>
          </Box>
        </div>
      )
      :
      arr.map(item =>
        <div
          className="stakeItemBg"
          onClick={() => {
            // console.log("onClick ItemComponent selected::", selected);
            if (selected.includes(item)) {
              setSelected(selected.filter(i => i !== item));
            } else {
              setSelected([...selected, item]);
            }
            // console.log("click stakeItem", item);
          }}
        >
          {selected.includes(item) && (
            <div className="stakeItemAcitve">
              <img src={Selected}/>
            </div>
          )}
          <div className="stakeItem">
            <div className="stakeItem-t">
              <Box display="flex" justifyContent="center">
                {/* <img src={getTeamImage(index)} className="" /> */}
                <video x5-video-player-type="h5" playsInline muted id="video" preload="none" autoPlay loop
                       className="header-bg-video" poster="" height="200">
                  <source src={getTeamImage(index)} type="video/mp4"/>
                </video>
              </Box>
              <Box display="flex" justifyContent="space-between" alignItems={"center"} className="tier">
                <div className="font-18">{teamIdToName(index)}</div>
                <Box display="flex" alignItems={"center"}>
                  <div className="font-15 font-weight-4">Tier</div>
                  <div className="ml-4">{teamIdToTier(index)}</div>
                </Box>
              </Box>
            </div>
          </div>
        </div>
      )
  }

  function stakeButton() {
    return (
      <Box display="flex" justifyContent="flex-end" className="mt-24">
        <div className="stakeBtnBox3">
          <Button
            variant="contained"
            className="stakeBtn"
            onClick={() => {
              handleStake(selected, selectedRound);
            }}
          >
            {isApprovalForAll ? "Stake" : "ApproveAll"}
          </Button>
        </div>
      </Box>
    )
  }

  function StageInfo() {
    return (
      <Box
        display="flex"
        flexDirection={isVerySmallScreen ? "column" : "row"}
        justifyContent="space-between"
        alignItems={isVerySmallScreen ? "flex-start" : "center"}
        className="liveInfo"
      >
        <div className="">
          <Box display="flex" alignItems="center" className={""}>
            <img src={Live}/>
            <div className="color10 font-15 ml-8">live</div>
          </Box>
          <div
            className="liveRound mt-8 font-24 font-family-Hi-Jack line-height-24">{walletInfo().walletInfoTitle}</div>
        </div>
        <Box
          display="flex"
          flexDirection={isVerySmallScreen ? "column" : "row"}
          alignItems={isVerySmallScreen ? "flex-start" : "center"}
        >
          <div className={`${isVerySmallScreen && "mt-16"}`}>
            <div className="color3">Prize of this Round</div>
            <div
              className="mt-8 color8 font-16 font-weight-b line-height-24">{ethers.utils.formatEther(walletInfo().prizeOfThisRound)} BNB
            </div>
          </div>
          <div className={`${isVerySmallScreen ? "mt-16" : "ml-30"}`}>
            <div className="color3">Total Stake</div>
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Box display="flex" justifyContent="space-between" className="font-13">
                    <div className="color4 font-weight-5">Tire 1</div>
                    <div className="font-weight-6">{walletInfo().stakedTier1}</div>
                  </Box>
                  <Box display="flex" justifyContent="space-between" className="">
                    <div className="color4 font-weight-5">Tire 2</div>
                    <div className="font-weight-6">{walletInfo().stakedTier2}</div>
                  </Box>
                  <Box display="flex" justifyContent="space-between" className="">
                    <div className="color4 font-weight-5">Tire 3</div>
                    <div className="font-weight-6">{walletInfo().stakedTier3}</div>
                  </Box>
                  <Box display="flex" justifyContent="space-between" className="">
                    <div className="color4 font-weight-5">Tire 4</div>
                    <div className="font-weight-6">{walletInfo().stakedTier4}</div>
                  </Box>
                </React.Fragment>
              }
            >
              <div
                className="mt-8 color8 font-16 font-weight-b line-height-24 underline">{walletInfo().totalStaked}</div>
            </HtmlTooltip>
          </div>
          <div className={`${isVerySmallScreen ? "mt-16" : "ml-30"}`}>
            <div className="color3">Est. Daily Yield per NFT</div>
            <div
              className="mt-8 color8 font-16 font-weight-b line-height-24">{walletInfo().totalStaked * 1 > 0 ? ethers.utils.formatEther(
              BigNumber.from(walletInfo().prizeOfThisRound)
                .div(walletInfo().totalStaked)
                .div(BigNumber.from(walletInfo().endTimestamp).sub(BigNumber.from(walletInfo().startTimestamp)))
                .mul(BigNumber.from("86400")).toString()
            ) : "0"} BNB
            </div>
          </div>
        </Box>
      </Box>
    )
  }


  return (
    <Dialog open={show} onClose={handleClose}>
      <div className="dialogBg">
        <div className="dialogContent">
          <Box display="flex" justifyContent="space-between" alignItems="center" className="dialogHeader">
            <div className="font-24 font-weight-b">Wallet</div>
            <img src={Close} onClick={handleClose}/>
          </Box>
          <div className="stakeInfo">
            {fromPage !== "Header" && StageInfo()}
            <Box
              display="flex"
              flexDirection={isVerySmallScreen ? "column" : "row"}
              justifyContent="space-between"
              alignItems={isVerySmallScreen ? "flex-start" : "center"}
              className="mt-6"
            >
              <div className="" style={{position: "relative"}}>
                <Menu>
                  <Menu.Button as="div">
                    <Box display="flex" justifyContent="space-between" alignItems="center" className="selectBg pointer">
                      <div className="font-15 color8">{currTier}</div>
                      <img src={Select}/>
                    </Box>
                  </Menu.Button>
                  <div className="chart-token-menu">
                    <Menu.Items as="div" className="menu-items chart-token-menu-items">
                      {TierList.map((item, index) => (
                        <Menu.Item key={index}>
                          <div className="menu-item" onClick={() => changeTier(index)}>
                            <span style={{marginLeft: 6}} className="token-label font-15">
                              {item}
                            </span>
                          </div>
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </div>
                </Menu>
              </div>
              {fromPage !== "Header" &&
                <div onClick={() => {
                  handleSelectAll();
                }} className={`pointer font-14 color6 font-weight-6 ${isVerySmallScreen && "mt-16"}`}>Select All</div>}
            </Box>
            <div className="bg mt-16">
              <Box className="stakeList font-14 font-weight-b" display="grid">
                {(userTotalItem > 0 && selectedTierTotalCount > 0) ? userAllIdCount
                    .map((item, index) => {
                      let loopCount = Number(item);
                      let loopArr = [];
                      for (let i = 0; i < loopCount; i++) {
                        loopArr.push(index + ":" + i);
                        // filter Tier
                        if (selectedFilterTier > 0 && selectedFilterTier !== teamIdToTier(index)) {
                          loopArr.pop();
                        }
                      }
                      return ItemComponent(loopArr, index)
                    })
                  : <Box display="flex" justifyContent="center" alignItems={"center"} className="text-center"
                         style={{height: 80}}>
                    No items to display
                  </Box>
                }
              </Box>
            </div>
            {fromPage !== "Header" && stakeButton()}
          </div>
        </div>
      </div>
    </Dialog>
  );
}
