import { ethers } from "ethers";
import { addresses } from "../constants";
import { abi as FootballNFTAbi } from "../abis/FootballNFT.json";
import { setAll } from "../helpers";

import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { IBaseAddressAsyncThunk } from "./interfaces";

export const loadAccountDetails = createAsyncThunk(
    "account/loadAccountDetails",
    async ({ address, networkID, provider }: IBaseAddressAsyncThunk) => {
        
        const FootballNFTContract = new ethers.Contract(addresses[networkID].FootballNFT, FootballNFTAbi, provider)
        console.log(FootballNFTContract)
        const mintPriceArr = await FootballNFTContract.mintPrice()
        console.log(mintPriceArr);
        
        return {
           
        };
    },
);

interface IAccountSlice {
    loading: boolean;
    opCount: number;
}


const initialState: IAccountSlice = {
    loading: false,
    opCount: 0,
};

const accountSlice = createSlice({
    name: "account",
    initialState,
    reducers: {
        fetchAccountSuccess(state, action) {
            setAll(state, action.payload);
        },
    },
    extraReducers: builder => {
        builder
            .addCase(loadAccountDetails.pending, state => {
                state.loading = true;
            })
            .addCase(loadAccountDetails.fulfilled, (state, action) => {
                setAll(state, action.payload);
                state.loading = false;
            })
            .addCase(loadAccountDetails.rejected, (state, { error }) => {
                state.loading = false;
            })
    },
});

export default accountSlice.reducer;

export const { fetchAccountSuccess } = accountSlice.actions;

const baseInfo = (state: RootState) => state.account;

export const getAccountState = createSelector(baseInfo, account => account);
