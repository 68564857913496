import Argentina from "../assets/teamVideo/Argentina.mp4";
import Australia from "../assets/teamVideo/Australia.mp4";
import Belgium from "../assets/teamVideo/Belgium.mp4";
import Brazil from "../assets/teamVideo/Brazil.mp4";
import Cameroon from "../assets/teamVideo/Cameroon.mp4";
import Canada from "../assets/teamVideo/Canada.mp4";
import CostaRica from "../assets/teamVideo/CostaRica.mp4" ;
import Croatia from "../assets/teamVideo/Croatia.mp4";
import Denmark from "../assets/teamVideo/Denmark.mp4";
import Ecuador from "../assets/teamVideo/Ecuador.mp4";
import England from "../assets/teamVideo/England.mp4";
import France from "../assets/teamVideo/France.mp4";
import Germany from "../assets/teamVideo/Germany.mp4";
import Ghana from "../assets/teamVideo/Ghana.mp4";
import Iriran from "../assets/teamVideo/Iriran.mp4";
import Japan from "../assets/teamVideo/Japan.mp4";
import Korea from "../assets/teamVideo/Korea.mp4";
import Mexico from "../assets/teamVideo/Mexico.mp4";
import Morocco from "../assets/teamVideo/Morocco.mp4";
import Netherlands from "../assets/teamVideo/Neherlands.mp4";
import Poland from "../assets/teamVideo/Poland.mp4";
import Portugal from "../assets/teamVideo/Portugal.mp4";
import Qatar from "../assets/teamVideo/Qatar.mp4";
import SaudiArabia from "../assets/teamVideo/SaudiArabia.mp4";
import Senegal from "../assets/teamVideo/Senegal.mp4";
import Serbia from "../assets/teamVideo/Serbia.mp4";
import Spain from "../assets/teamVideo/Spain.mp4";
import Switzerland from "../assets/teamVideo/Switzerland.mp4";
import Tunisia from "../assets/teamVideo/Tunisia.mp4";
import Uruguay from "../assets/teamVideo/Uruguay.mp4";
import USA from "../assets/teamVideo/USA.mp4";
import Wales from "../assets/teamVideo/Wales.mp4";


const teamName = ["Qatar", "England", "Argentina", "France", "Spain", "Belgium", "Brazil", "Portugal", "Netherlands", "USA", "Mexico", "Germany", "Croatia", "Switzerland", "Uruguay", "Denmark", "Senegal", "Iriran", "Poland", "Tunisia", "Japan", "Serbia", "Morocco", "Korea", "Ecuador", "Wales", "SaudiArabia", "Australia", "CostaRica", "Canada", "Cameroon", "Ghana"];

function teamIdToName(teamId) {
  return teamName[teamId];
}

const tier1 = [0, 1, 2, 3, 4, 5, 6, 7];
const tier2 = [8, 9, 10, 11, 12, 13, 14, 15];
const tier3 = [16, 17, 18, 19, 20, 21, 22, 23];
const tier4 = [24, 25, 26, 27, 28, 29, 30, 31];

function getTierIdList(tier) {
  if (tier === 1) {
    return tier1;
  } else if (tier === 2) {
    return tier2;
  } else if (tier === 3) {
    return tier3;
  } else if (tier === 4) {
    return tier4;
  }
  return [];
}

function teamIdToTier(teamId) {


  let tier = null;
  if (tier1.includes(teamId)) {
    tier = 1;
  } else if (tier2.includes(teamId)) {
    tier = 2;
  } else if (tier3.includes(teamId)) {
    tier = 3;
  } else if (tier4.includes(teamId)) {
    tier = 4;
  }
  return tier;
}

function getTeamImage(teamId) {
  if (teamId === 0) {
    return Qatar;
  } else if (teamId === 1) {
    return England;
  } else if (teamId === 2) {
    return Argentina;
  } else if (teamId === 3) {
    return France;
  } else if (teamId === 4) {
    return Spain;
  } else if (teamId === 5) {
    return Belgium;
  } else if (teamId === 6) {
    return Brazil;
  } else if (teamId === 7) {
    return Portugal;
  } else if (teamId === 8) {
    return Netherlands;
  } else if (teamId === 9) {
    return USA;
  } else if (teamId === 10) {
    return Mexico;
  } else if (teamId === 11) {
    return Germany;
  } else if (teamId === 12) {
    return Croatia;
  } else if (teamId === 13) {
    return Switzerland;
  } else if (teamId === 14) {
    return Uruguay;
  } else if (teamId === 15) {
    return Denmark;
  } else if (teamId === 16) {
    return Senegal;
  } else if (teamId === 17) {
    return Iriran;
  } else if (teamId === 18) {
    return Poland;
  } else if (teamId === 19) {
    return Tunisia;
  } else if (teamId === 20) {
    return Japan;
  } else if (teamId === 21) {
    return Serbia;
  } else if (teamId === 22) {
    return Morocco;
  } else if (teamId === 23) {
    return Korea;
  } else if (teamId === 24) {
    return Ecuador;
  } else if (teamId === 25) {
    return Wales;
  } else if (teamId === 26) {
    return SaudiArabia;
  } else if (teamId === 27) {
    return Australia;
  } else if (teamId === 28) {
    return CostaRica;
  } else if (teamId === 29) {
    return Canada;
  } else if (teamId === 30) {
    return Cameroon;
  } else if (teamId === 31) {
    return Ghana;
  }
}

function getRoundNameWithRoundId(roundId) {
  const roundNames = ["Group Stage", "Round of 16", "Quarter-finals", "Semi-finals", "3RD Place", "2RD Place", "1ST Place"];
  return roundNames[roundId];
}

export {teamIdToName, teamIdToTier, getTeamImage, getRoundNameWithRoundId, getTierIdList};

