interface IAddresses {
    [key: number]: { [key: string]: string };
}

export const addresses: IAddresses = {
    97: {
        "randomSource": "0xd25170Eb42Db06b924dfa339be05218414A6B87c",
        "FootballNFT": "0x188AB5336b14f93c5EC922641134f9AfF78c9F9a",
        "NFTGame": "0xd571DaEcBafe9e1EE6d1Fdd24381704692A38c89",
    },

    56: {
        "FootballNFT": "0x0A074378461FB7ed3300eA638c6Cc38246db4434",
        "NFTGame": "0xd96a19eF17b27c10B27DE5988F4C392F5036782b",
    },
};



