import { Box, Button, Dialog, Link, SvgIcon } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import copy from "copy-to-clipboard";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { useWeb3Context } from "src/hooks";
import { success } from "src/slices/MessagesSlice";
import world from "../../assets/images/3d.svg";
import Close from "../../assets/images/close.svg";
import Discord from "../../assets/images/links/discord.svg";
import Doc from "../../assets/images/links/doc.svg";
import Medium from "../../assets/images/links/medium.svg";
import Twitter from "../../assets/images/links/twitter.svg";
import Logo from "../../assets/images/logo.svg";
import { ReactComponent as Wallet } from "../../assets/images/wallet.svg";
import { shorten } from "../../helpers";
import "../../views/stake/dialog.scss";
import "./header.scss";
import WalletDialog from "../../views/stake/walletDialog";
import useStake from "../../hooks/useStake";

export default function Header() {
  const [showWallet, setShowWallet] = useState(false);
  const [selectedRound, setSelectedRound] = useState(777);
  const { isApprovalForAll, roundInfoListState } = useStake();

  const isSmallScreen = useMediaQuery("(max-width: 1080px)");

  const dispatch = useDispatch();
  const pendingTransactions = useSelector(state => {
    return state.pendingTransactions;
  });
  const location = useLocation();
  const [showInfo, setShowInfo] = useState(false);
  const [pathname, setPathname] = useState(location.pathname);
  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  const { connect, disconnect, connected, web3, address, chainID, switchNetwork2 } = useWeb3Context();
  const [isConnected, setConnected] = useState(connected);
  let buttonText = "Connect Wallet";
  let clickFunc = () => {
    if (isConnected) {
      setShowInfo(true);
    } else {
      connect();
    }
  };

  if (isConnected) {
    buttonText = shorten(address); // "Disconnect";
    // clickFunc = disconnect;
  }
  const handleClose2 = () => {
    setShowInfo(false);
  };

  const handlerLogout = disconnect;

  useEffect(() => {
    setConnected(connected);
  }, [web3, connected]);

  return (
    <Box display="flex" justifyContent="center" className="header-view">
      <div className="container">
        <Box display="flex" alignItems="center" justifyContent="space-between" className="header">
          <Box display="flex" alignItems="center" className="">
            <a href="https://ede.finance/" target="_blank">
              <img src={Logo} alt="logo" className="logo" />
            </a>
            {!isSmallScreen && (
              <Box display="flex" alignItems={"center"}>
                <div className="line ml-16"></div>
                <img src={world} className="ml-24" />
                <Box display="flex" className="menuList font-16">
                  <Link component={NavLink} to="/" className={`menuItem ${pathname == "/" && "menuItemActive"}`}>
                    Mint
                  </Link>
                  <Link
                    component={NavLink}
                    to="/Stake"
                    className={`menuItem ${pathname == "/Stake" && "menuItemActive"}`}
                  >
                    Stake
                  </Link>
                </Box>
              </Box>
            )}
          </Box>
          <Box display="flex" alignItems="center" className="">
            <Box
              display="flex"
              alignItems={"center"}
              justifyContent={"center"}
              className={`staked pointer text-center border-radius-8 ${isSmallScreen ? "font-14 font-weight-5" : " font-weight-b font-16"}`}
              onClick={() => {
                // setSelectedRound(currentRoundIndex);
                setShowWallet(true)
              }}
            >
              My Wallet
            </Box>
            {!isSmallScreen && (
              <Box display="flex" alignItems="center" className="links ml-16">
                <a href="https://docs.ede.finance/welcome-to-ede-finance/special-event" target={"_blank"}>
                  <img src={Doc} />
                </a>
                <a href="https://medium.com/@ede_finance" target={"_blank"}>
                  <img src={Medium} className="ml-30" />
                </a>
                <a href="https://twitter.com/ede_finance" target={"_blank"}>
                  <img src={Twitter} className="ml-30" />
                </a>
                <a href="https://discord.com/invite/g7GpVVxtxz" target={"_blank"}>
                  <img src={Discord} className="ml-30" />
                </a>
              </Box>
            )}
            <div className={`connectBtnBox ${isSmallScreen ? "ml-12" : "ml-16"}`} onClick={clickFunc}>
              <Button variant="contained" className="connectBtn">
                <SvgIcon className="downIcon" component={Wallet} viewBox="0 0 22 23" />
                <span className={`${isSmallScreen ? "ml-6" : "ml-12"}`}>{buttonText}</span>
              </Button>
            </div>
          </Box>
        </Box>
      </div>
      <Dialog open={showInfo} onClose={handleClose2}>
        <div className="dialogBg dialogBg-wallet">
          <div className="dialogContent">
            <Box display="flex" justifyContent="space-between" alignItems="center" className="dialogHeader">
              <div className="">
                <div className="font-18 font-weight-b">My Wallet</div>
              </div>
              <img src={Close} onClick={handleClose2} />
            </Box>
            <div className="networkList font-16">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                className={`networkItem ${connected && chainID == 97 ? "networkItemActive" : ""}`}
              >
                <Box display="flex" alignItems="center" className="networkItemLeft">
                  <div className="ml-12">{shorten(address)}</div>
                </Box>
                <Box display="flex" alignItems="center" className="isConnected">
                  <div className="font-14">Connected</div>
                  <div className="connected"></div>
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection={isSmallScreen ? "column" : "row"}
                justifyContent={isSmallScreen ? "flex-start" : "space-between"}
                alignItems="center"
                className="mt-24"
              >
                <div
                  className="connectBtnBox"
                  onClick={() => {
                    copy(address), dispatch(success("copy success"));
                  }}
                >
                  <Button variant="contained" className="connectBtn ml-12">
                    <span className="">Copy Address</span>
                  </Button>
                </div>
                <div
                  className={`connectBtnBox ${isSmallScreen && "mt-12"}`}
                  onClick={() => {
                    window.open(`https://testnet.bscscan.com/address/${address}`);
                  }}
                >
                  <Button variant="contained" className="connectBtn ml-12">
                    <span className="">View in Explorer</span>
                  </Button>
                </div>
                <div className={`connectBtnBox ${isSmallScreen && "mt-12"}`} onClick={handlerLogout}>
                  <Button variant="contained" className="stakeBtn">
                    Disconnect
                  </Button>
                </div>
              </Box>
            </div>
          </div>
        </div>
      </Dialog>
      <WalletDialog show={showWallet} setShowWallet={setShowWallet} selectedRound={selectedRound}
        isApprovalForAll={isApprovalForAll} fromPage={"Header"} />
    </Box>
  );
}
