import {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {addresses} from 'src/constants';
import {IJsonRPCError} from 'src/slices/interfaces';
import {error} from 'src/slices/MessagesSlice';
import {clearPendingTxn, fetchPendingTxns} from 'src/slices/PendingTxnsSlice';
import {useWeb3Context} from '.'
import {ethers, BigNumber} from "ethers";
import {abi as NFTGameAbi} from "../abis/NFTGame1155.json";
import {loadAppDetails} from 'src/slices/AppSlice';
import {abi as NFTabi} from "../abis/FootballNFT.json";


export default function useWalletInfo() {
    // console.log("this is useWalletInfo")

    const {chainID, address, provider} = useWeb3Context()
    const dispatch = useDispatch()

    const [userAllIdCount, setUserAllIdCount] = useState([])
    const [stakedAllNFT, setStakedAllNFT] = useState([] as any)
    const [totalPenalties, setTotalPenalties] = useState({
        totalPanaltyFee: "0",
        totalClaimable: "0",
        totalRealPanaltyFee: "0",
        panaltyFeList: [],
    } as any)
    const [totalReceived, setTotalReceived] = useState(BigNumber.from(0) as any);
    const [userTotalItem, setUserTotalItem] = useState(0);


    const counter = useSelector(state => {
        // @ts-ignore
        return state.counter;
    });

    const [count, setCount] = useState(0)
    useEffect(() => {
        // console.log('开启定时器')
        const timer = setInterval(() => {
            // console.log('进入定时器')
            // 注:在setCount中使用箭头函数是最好方式之一,只有一个timer生成
            setCount((count) => count + 1)
        }, 1000)
        return () => {
            // console.log('清除定时器')
            clearInterval(timer)
        }
    }, []) //如果设置count依赖,会有多个timer生成,销毁

    useEffect(() => {

        (async () => {
            try {
                if (provider && address) {
                    // console.log("useClaim::", chainID, address, provider)
                    const NFTGameContract = new ethers.Contract(addresses[chainID].NFTGame, NFTGameAbi, provider);

                    let userStakingDetails = await NFTGameContract.getUserStakingDetails(address);
                    let paramForGetPanaltyFee_tokenStakingIdList = [];
                    let userTotalReceived = BigNumber.from(0);

                    let stakedInfoList = [];
                    let infoLen = 8;
                    for (let i = 0; i < userStakingDetails.length / infoLen; i++) {
                        let stakedInfo = {
                            stakingContractId: Number(userStakingDetails[i * infoLen + 0]) + '',
                            stakingStartTimeStamp: Number(userStakingDetails[i * infoLen + 1]) + '',
                            claimable: Number(userStakingDetails[i * infoLen + 2]) + '',
                            totalProfit: Number(userStakingDetails[i * infoLen + 3]) + '',
                            claimedProfit: Number(userStakingDetails[i * infoLen + 4]) + '',
                            teamId: Number(userStakingDetails[i * infoLen + 5]) + '',
                            stakingRound: Number(userStakingDetails[i * infoLen + 6]) + '',
                            tier: Number(userStakingDetails[i * infoLen + 7]) + '',
                        }
                        stakedInfoList.push(stakedInfo)

                        paramForGetPanaltyFee_tokenStakingIdList.push(stakedInfo.stakingContractId)

                        // userTotalReceived = Number(userTotalReceived) + Number(stakedInfo.totalProfit);
                        userTotalReceived = userTotalReceived.add(BigNumber.from(stakedInfo.claimedProfit));
                    }

                    // console.log("useWalletInfo useEffect stakedInfoList::", stakedInfoList)
                    setStakedAllNFT(stakedInfoList);
                    setTotalReceived(userTotalReceived);

                    let userTotalPenalties = await NFTGameContract.getPanaltyFee(paramForGetPanaltyFee_tokenStakingIdList);


                    if (userTotalPenalties[3] && userTotalPenalties[3].length > 0) {
                        let panaltyFeList = [];
                        for (let i = 0; i < userTotalPenalties[3].length; i++) {
                            panaltyFeList.push(Number(userTotalPenalties[3][i]) + '')
                        }


                        let penaltyInfo = {
                            totalPanaltyFee: Number(userTotalPenalties[0]) + '',
                            totalClaimable: Number(userTotalPenalties[1]) + '',
                            totalRealPanaltyFee: Number(userTotalPenalties[2]) + '',
                            panaltyFeList: panaltyFeList,
                        }

                        // console.log("useWalletInfo useEffect penaltyInfo::", penaltyInfo);

                        setTotalPenalties(penaltyInfo);
                    }


                }
            } catch (e) {
                console.log(e)
                const rpcError = e as IJsonRPCError;
                // rpcError.data ? dispatch(error(rpcError.data.message)) : dispatch(error(rpcError.message))
            }
        })()

        return () => {
        };

    }, [chainID, address, provider, counter, count]);

    useEffect(() => {
        (async () => {
            try {
                if (provider && address) {
                    // console.log("useClaim::", chainID, address, provider)
                    const FootballNFTContract = new ethers.Contract(addresses[chainID].FootballNFT, NFTabi, provider);

                    let param_addresses = [];
                    let param_ids = [];
                    for (let i = 0; i < 32; i++) {
                        param_addresses.push(address);
                        param_ids.push(i);
                    }

                    const userIdsInfo = await FootballNFTContract.balanceOfBatch(param_addresses, param_ids);
                    // console.log("!!!userIdsInfo!!!",userIdsInfo)


                    let totalItem = 0;
                    for (let i = 0; i < userIdsInfo.length; i++) {
                        let loopCount = Number(userIdsInfo[i]);
                        totalItem += loopCount;
                        setUserTotalItem(totalItem);
                    }

                    setUserAllIdCount(userIdsInfo);
                }
            } catch (e) {
                console.log(e)
                const rpcError = e as IJsonRPCError;
                // rpcError.data ? dispatch(error(rpcError.data.message)) : dispatch(error(rpcError.message))
            }
        })()

        return () => {
        };
    }, [chainID, address, provider, counter]);


    return {userTotalItem, userAllIdCount, stakedAllNFT, totalPenalties, totalReceived}
}


